@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[4\.5vmin\] {
  margin-top: 4.5vmin;
}

.mt-\[5\.15vmin\] {
  margin-top: 5.15vmin;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[10vmin\] {
  height: 10vmin;
}

.h-\[13vmin\] {
  height: 13vmin;
}

.h-\[24vmin\] {
  height: 24vmin;
}

.h-\[50vmin\] {
  height: 50vmin;
}

.h-\[65vmin\] {
  height: 65vmin;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[7vmin\] {
  height: 7vmin;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-\[90vmin\] {
  height: 90vmin;
}

.h-\[92vh\] {
  height: 92vh;
}

.max-h-full {
  max-height: 100%;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[10vmin\] {
  width: 10vmin;
}

.w-\[21vmin\] {
  width: 21vmin;
}

.w-\[24vmin\] {
  width: 24vmin;
}

.w-\[28vw\] {
  width: 28vw;
}

.w-\[40vmin\] {
  width: 40vmin;
}

.w-\[45vw\] {
  width: 45vw;
}

.w-\[50vw\] {
  width: 50vw;
}

.w-\[70vw\] {
  width: 70vw;
}

.w-\[73vw\] {
  width: 73vw;
}

.w-\[7vw\] {
  width: 7vw;
}

.w-\[81vw\] {
  width: 81vw;
}

.w-\[85vw\] {
  width: 85vw;
}

.w-\[90vw\] {
  width: 90vw;
}

.w-\[96vw\] {
  width: 96vw;
}

.max-w-full {
  max-width: 100%;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-\[17vmin\] {
  column-gap: 17vmin;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-\[5vmin\] {
  row-gap: 5vmin;
}

.gap-y-\[7\.5vmin\] {
  row-gap: 7.5vmin;
}

.rounded-\[2\.5vmin\] {
  border-radius: 2.5vmin;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[2\.5px\] {
  border-width: 2.5px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border-\[\#c5c5c5\] {
  --tw-border-opacity: 1;
  border-color: rgb(197 197 197 / var(--tw-border-opacity));
}

.border-\[\#e4e4e4\] {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 228 / var(--tw-border-opacity));
}

.bg-\[\#128496\] {
  --tw-bg-opacity: 1;
  background-color: rgb(18 132 150 / var(--tw-bg-opacity));
}

.bg-\[\#157186\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 113 134 / var(--tw-bg-opacity));
}

.bg-\[\#206ebb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(32 110 187 / var(--tw-bg-opacity));
}

.bg-\[\#e0bb02\] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 187 2 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-\[7vmin\] {
  padding: 7vmin;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-center {
  text-align: center;
}

.font-Montserrat {
  font-family: Montserrat;
}

.text-\[10vmin\] {
  font-size: 10vmin;
}

.text-\[2\.75vmin\] {
  font-size: 2.75vmin;
}

.text-\[3\.35vmin\] {
  font-size: 3.35vmin;
}

.text-\[3\.4vmin\] {
  font-size: 3.4vmin;
}

.text-\[3\.5vmin\] {
  font-size: 3.5vmin;
}

.text-\[4\.25vmin\] {
  font-size: 4.25vmin;
}

.text-\[4\.5vmin\] {
  font-size: 4.5vmin;
}

.text-\[5vmin\] {
  font-size: 5vmin;
}

.text-\[7\.35vmin\] {
  font-size: 7.35vmin;
}

.text-\[8\.5vmin\] {
  font-size: 8.5vmin;
}

.text-\[8vmin\] {
  font-size: 8vmin;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.text-\[\#128496\] {
  --tw-text-opacity: 1;
  color: rgb(18 132 150 / var(--tw-text-opacity));
}

.text-\[\#157186\] {
  --tw-text-opacity: 1;
  color: rgb(21 113 134 / var(--tw-text-opacity));
}

.text-\[\#414141\] {
  --tw-text-opacity: 1;
  color: rgb(65 65 65 / var(--tw-text-opacity));
}

.text-\[\#494949\] {
  --tw-text-opacity: 1;
  color: rgb(73 73 73 / var(--tw-text-opacity));
}

.text-\[\#616161\] {
  --tw-text-opacity: 1;
  color: rgb(97 97 97 / var(--tw-text-opacity));
}

.text-\[\#919191\] {
  --tw-text-opacity: 1;
  color: rgb(145 145 145 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-90 {
  opacity: .9;
}

body {
  overflow-x: hidden;
}

textarea:focus, input:focus {
  outline: none;
}

@media (width >= 768px) {
  .md\:h-\[10px\] {
    height: 10px;
  }

  .md\:h-\[40vmin\] {
    height: 40vmin;
  }

  .md\:w-\[17vmin\] {
    width: 17vmin;
  }

  .md\:w-\[40vmin\] {
    width: 40vmin;
  }

  .md\:w-\[90vw\] {
    width: 90vw;
  }

  .md\:text-\[7\.5vmin\] {
    font-size: 7.5vmin;
  }
}

@media (width >= 1024px) {
  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-3 {
    margin-left: .75rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:h-\[100vh\] {
    height: 100vh;
  }

  .lg\:h-\[55vmin\] {
    height: 55vmin;
  }

  .lg\:h-\[90vh\] {
    height: 90vh;
  }

  .lg\:h-\[90vmin\] {
    height: 90vmin;
  }

  .lg\:w-\[29\.5vw\] {
    width: 29.5vw;
  }

  .lg\:w-\[30vw\] {
    width: 30vw;
  }

  .lg\:w-\[33vw\] {
    width: 33vw;
  }

  .lg\:w-\[35vw\] {
    width: 35vw;
  }

  .lg\:w-\[40vw\] {
    width: 40vw;
  }

  .lg\:w-\[5vw\] {
    width: 5vw;
  }

  .lg\:w-\[70vw\] {
    width: 70vw;
  }

  .lg\:w-\[75vmin\] {
    width: 75vmin;
  }

  .lg\:w-\[80vmin\] {
    width: 80vmin;
  }

  .lg\:gap-x-\[30vmin\] {
    column-gap: 30vmin;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:text-\[2\.45vmin\] {
    font-size: 2.45vmin;
  }

  .lg\:text-\[2\.75vmin\] {
    font-size: 2.75vmin;
  }

  .lg\:text-\[3\.5vmin\] {
    font-size: 3.5vmin;
  }

  .lg\:text-\[4vmin\] {
    font-size: 4vmin;
  }
}
/*# sourceMappingURL=index.35bd0dcf.css.map */
